.sidebar {
    height: 100vh;

    position: fixed;
    top: 0;

    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 767.98px) {
        top: 5rem;
    }

    &-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: 0.5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    .nav-link {
        font-weight: 500;
        color: #333;
    }

    .nav-link .feather {
        margin-right: 4px;
        color: #727272;
    }

    .nav-link.active {
        color: #2470dc;
    }

    .nav-link:hover .feather,
    .nav-link.active .feather {
        color: inherit;
    }

    &-heading {
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}
