@import '~bootstrap/scss/bootstrap';

@import './assets/sass/typography';
@import 'nprogress/nprogress.css';
@import 'react-confirm-alert/src/react-confirm-alert.css';
@import 'bootstrap-daterangepicker/daterangepicker.css';

body {
    font-size: 0.875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}
