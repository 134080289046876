.anchor {
  cursor: pointer;
}

.terminated {
  &.ljBFnW {
    background-color: #a020f0;
    color: #ffffff;
  }

  svg {
    color: #ffffff;
  }
}
